<template>
    <div class="news">
        <main>
            <div class="overlay"></div>
            <img src="https://cms.aplesports.com/storage/uploads/2020/05/06/5eb29cbda00981920x400_4.png">
            <div class="container">
                <div class="title main">news</div>
            </div>
        </main>

        <section class="articles">
            <div class="container">    
                <div class="horizontal-card" v-for="p in displayPosts" :key="p._id">
                    <div class="card__header">
                        <div class="card__header__img"><img :src=" imgRoot + p.image.path" alt=" "></div>
                    </div>
                    <div class="card__body">
                        <div class="card__body__title">{{ p.title }}</div>
                        <div class="card__body__author">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                            </svg>
                            {{ p.author }}
                        </div>
                        <div class="card__body__content" v-html="p.excerpt"></div>
                        <div class="card__body__cta">
                            <router-link v-if="p.link" :to="'/news/' + p.link">Read More</router-link>
                            <router-link v-else :to="'/news/' + p.title_slug">Read More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
const md = require('markdown-it')({
    breaks: true,
    html: true
});

export default {
    name: 'News',
    data() {
        return {
            posts: {},
            displayPosts: {},
            count: 7,
            bottom: false,
            total: 1,
            imgRoot: 'https://cms.aplesports.com/storage/uploads'
        }
    },
    created() {
        axios.post("https://cms.aplesports.com/api/collections/get/News", {
            "sort": {
                "_created": -1
            },
            "filter": {
                "published": true
            },
            "fields": {
                "title": 1,
                "title_slug": 1,
                "image": 1,
                "excerpt": 1,
                "_by": 1,
                "link": 1
            }
            } ,{
            headers: {
                'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
            }
        })
        .then((res) => {
            this.posts = res.data.entries;
			this.posts.forEach((post) => {
				post.excerpt = md.render(post.excerpt);
			});
            this.displayPosts = this.posts.slice(0, this.count);
            this.total = res.data.total;
        });
        window.addEventListener('scroll', () => {
            this.bottom = this.bottomVisible();
        });
    },
    methods: {
        bottomVisible() {
            return (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight ? true : false);
        },
        addNews() {
            if(this.displayPosts.length == this.posts.length) {
                return false;
            }
            let n = this.posts.slice(this.count, this.count + 1);
            this.displayPosts.push(n[0]);
            this.count += 1;
        }
    },
    mounted() {

    },
    watch: {
        bottom(bottom) {
            if(bottom) {
                this.addNews();
            }
        }
    }
}
</script>
