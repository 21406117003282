<template>
    <div class="article">
        <article>
            <div class="image">
                <div class="overlay"></div>
                <img :src="imgRoot + this.image" alt=" ">
            </div>

            <div class="header">
                <div class="container">
                    <div class="title">{{ this.title }}</div>
                </div>
                <div class="author">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"/>
                    </svg>
                    {{ this.author }}
                </div>
            </div>

            <div class="body">
                <div class="container" v-html="this.content"></div>
            </div>
        </article>
    </div>
</template>

<script>
import axios from 'axios';
const md = require('markdown-it')({
    breaks: true,
    html: true
});

export default {
    name: 'Article',
    data() {
        return {
            title: null,
            author: null,
            id: null,
            image: null,
            content: null,
            imgRoot: 'https://cms.aplesports.com/storage/uploads'
        }
    },
    created() {
        this.id = this.$route.params.id;

        axios.post("https://cms.aplesports.com/api/collections/get/News", {
            "sort": {
                "_created": -1
            },
            "filter": {
                "published": true,
                "title_slug": this.id
            },
            "fields": {
                "title": 1,
                "title_slug": 1,
                "image": 1,
                "content": 1,
                "_by": 1,
                "link": 1
            }
            }, {
            headers: {
                'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
            }
        })
        .then((res) => {
            if(!res.data.entries.length == 0) {
                this.title = res.data.entries[0].title;
                this.author = res.data.entries[0].author;
                this.image = res.data.entries[0].image.path;
                this.content = md.render(res.data.entries[0].content);
            } else {
                axios.post("https://cms.aplesports.com/api/collections/get/News", {
                    "sort": {
                        "_created": -1
                    },
                    "filter": {
                        "published": true,
                        "link": this.id
                    },
                    "fields": {
                        "title": 1,
                        "title_slug": 1,
                        "image": 1,
                        "content": 1,
                        "_by": 1,
                        "link": 1
                    }
                    }, {
                    headers: {
                        'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
                    }
                })
                .then((res) => {
                    this.title = res.data.entries[0].title;
                    this.author = res.data.entries[0].author;
                    this.image = res.data.entries[0].image.path;
                    this.content = md.render(res.data.entries[0].content);
                });
            }
        });
    }
}
</script>
